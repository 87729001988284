import './styling.scss';

function sendConsentToAnalytics(active) {
	// check if gtag is available
	if (typeof gtag !== 'function') {
		window.dataLayer = window.dataLayer || []
      	function gtag(){dataLayer.push(arguments);}
	}

	const consentStatus = active == true ? 'granted' : 'denied';

	const consentMode = {
		'ad_storage': consentStatus,
		'ad_user_data': consentStatus,
		'ad_personalization': consentStatus,
		'analytics_storage':consentStatus
	};

	// set consent mode in local storage, as we need to check this on every page load in base.html.twig
	// this will be used just before tag manager is loaded in
	let localStorageConsentMode = getLocalStorageConsentMode();
	if (localStorageConsentMode === null) {
		localStorageConsentMode = setLocalStorageConsentMode(consentMode);
	}

	// send consent to google tag manager
	gtag('consent', 'update', consentMode);
}

function getLocalStorageConsentMode() {
	const localStorageConsentMode = localStorage.getItem('consentMode');

	if (localStorageConsentMode === null) {
		return null;
	}

	const parsedData = JSON.parse(localStorageConsentMode);

	return parsedData;
}

function setLocalStorageConsentMode(consentMode) {
	// get the current date
	const now = new Date();

	// add 6 months to the current date
	now.setMonth(now.getMonth() + 6);

	// add 30 seconds to the current date - Used to test expiry works
	// now.setSeconds(now.getSeconds() + 30);

	// create data object
	const data = {
		'consent': consentMode,
		'expiry': now
	};

	localStorage.setItem('consentMode', JSON.stringify(data));

	return data;
}

function checkLocalStorageConsentExpiryPassed() {
	const localStorageConsentMode = getLocalStorageConsentMode();

	if (localStorageConsentMode === null) {
		return;
	}

	// we should always have an expiry date, but just in case if its not set, we will remove the cookie
	if (localStorageConsentMode.expiry) {
		const expiryDate = new Date(localStorageConsentMode.expiry);
		const now = new Date();
	
		if (now > expiryDate) {
			clearCookie();
		}
	}
	else {
		clearCookie();
	}
}

function clearCookie() {
	localStorage.removeItem('consentMode');
}

function acceptCookiePolicy() {
	sendConsentToAnalytics(true);
	removeCookiePolicy();
}

function declineCookiePolicy() {
	removeCookiePolicy();
	sendConsentToAnalytics(false);
}

function removeCookiePolicy() {
	const cookiePolicy = document.querySelector('.cookie-policy-v2');
	if (cookiePolicy) {
		cookiePolicy.remove();
	}
}

function createCookiePolicy() {
	const body = document.querySelector('body');

	// create cookie policy wrapper
	const cookiePolicyWrapper = document.createElement('div');
	cookiePolicyWrapper.classList.add('cookie-policy-v2');

	// create cookie policy text
	const cookiePolicyText = document.createElement('p');
	cookiePolicyText.innerHTML = 'We use <i>cookies</i> to improve your experience on our website. Please let us know if you agree to all of these cookies.';

	// -------------------------
	// ACCEPT COOKIES
	// -------------------------
	const cookiePolicyButtonAccept = document.createElement('button');
	cookiePolicyButtonAccept.classList.add('cookie-policy-v2__btn', 'cookie-policy-v2__btn--accept');
	cookiePolicyButtonAccept.textContent = 'Yes, I agree';

	// set cookie policy on click to true
	cookiePolicyButtonAccept.onclick = acceptCookiePolicy;

	// -------------------------
	// DECLINE COOKIES
	// -------------------------
	const cookiePolicyButtonDecline = document.createElement('button');
	cookiePolicyButtonDecline.classList.add('cookie-policy-v2__btn');
	cookiePolicyButtonDecline.textContent = 'No, I decline';

	// set cookie policy on click to false
	cookiePolicyButtonDecline.onclick = declineCookiePolicy;

	// -------------------------
	// ESSENTIAL COOKIES
	// -------------------------
	const cookiePolicyButtonEssential = document.createElement('button');
	cookiePolicyButtonEssential.classList.add('cookie-policy-v2__btn');
	cookiePolicyButtonEssential.textContent = 'Only essential';

	// set cookie policy on click to true
	cookiePolicyButtonEssential.onclick = acceptCookiePolicy;

	// append elements to wrapper
	cookiePolicyWrapper.appendChild(cookiePolicyText);
	cookiePolicyWrapper.appendChild(cookiePolicyButtonAccept);
	cookiePolicyWrapper.appendChild(cookiePolicyButtonDecline);
	cookiePolicyWrapper.appendChild(cookiePolicyButtonEssential);

	// append wrapper to body
	body.appendChild(cookiePolicyWrapper);
}

function initiateCookiePolicy() {
	// check if cookie policy expiry has passed
	checkLocalStorageConsentExpiryPassed();

	const localStorageConsentMode = getLocalStorageConsentMode();

	// we do not want to load in the cookie policy more than once, so check if it already exists
	const getCookiePolicy = document.querySelectorAll('.cookie-policy-v2');

	// check if cookie policy has already been accepted
	if (localStorageConsentMode === null && getCookiePolicy.length === 0) {
		createCookiePolicy();
	}
}

// remove cookie during development
// clearCookie();

window.addEventListener('load', function() {
	initiateCookiePolicy();
});